<template>
  <div class="footer">
    <div class="footer-in">
      <a-col class="nav-link" :span="18">
        <ul class="gn-flex-start left">
          <li>
            <h2 @click="handleGo">招募合作</h2>
            <p class="active" @click="handleGoRecruitment(1)">合伙人招募</p>
            <p class="active" @click="handleGoRecruitment(2)">厂家招募</p>
          </li>
          <li>
            <h2>公司动态</h2>
            <p class="active" @click="handleGoInformation('0')">公司新闻</p>
            <!-- <p class="active" @click="handleGoInformation('1')">行业动态</p> -->
            <!-- <p class="active" @click="handleGoInformation('2')">市场巡展</p> -->
          </li>
          <li>
            <h2>宝乡通</h2>
            <p class="active" @click="handleGoSolution('retailStore')">功能介绍</p>
            <p class="active" @click="handleGoSolution('distributor')">应用实例</p>
            <p class="active" @click="handleGoSolution('manufacturer')">使用教程</p>
            <!-- <p class="active" @click="handleGoSolution('Case')">运营案例</p> -->
            <!-- <p class="active" @click="handleTest('Instructions')">使用图文教程</p> -->
          </li>
          <li>
            <h2>宝秤农资</h2>
            <p class="active" @click="handleGoBusiness('characteristic')">三大特色</p>
            <p class="active" @click="handleGoBusiness('instructions')">使用说明</p>
          </li>
          <li>
            <h2>关于我们</h2>
            <p class="active" @click="handleGoAbout('companyProfile')">企业简介</p>
            <p class="active" @click="handleGoAbout('corporateCulture')">企业文化</p>
            <p class="active" @click="handleGoAbout('developmentHistory')">发展历程</p>
            <p class="active" @click="handleGoAbout('enterpriseHonor')">获得荣誉</p>
            <!-- <p class="active" @click="handleGoAbout('')">人才招聘</p> -->
            <p class="active" @click="handleGoAbout('contact')">联系我们</p>
          </li>
        </ul>
      </a-col>
      <a-col :span="6">
        <ul class="img-list">
          <!-- <li>
            <h2>宝秤农资APP</h2>
            <img
              style="width: 123px; height: 122px"
              src="@/assets/download_code.png"
            />
          </li> -->
          <li>
            <h2>宝乡通公众号</h2>
            <img
              style="width: 123px; height: 122px"
              src="@/assets/official.png"
            />
          </li>
        </ul>
        <div class="img-box"></div>
      </a-col>
    </div>
    <div class="bottom gn-flex-center">
      <div class="footer-inner">
        <a href="https://beian.miit.gov.cn">粤ICP备17041355号</a> 广州市广农数链信息科技有限公司 @版权所有（C）2017-2022
      </div>
      <!-- <div class="footer-inner">
        <div>
          <img src="@/assets/icp.png" width="16" />
          <a href="https://beian.miit.gov.cn">粤ICP备17041355号</a>
          <div :class="{ 'gn-inline': isLargeScreen }">
            广州市广农数链信息科技有限公司 {{ copyright }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    isLargeScreen () {
      /** 参考bootstrop划分屏幕规则
       * 手机: <768px
       * 平板：<=768px
       * 电脑：>=992px
       * 大屏：>=1200px
       */
      return this.$store.state.IS_LARGE_SCREEN
    }
  },
  data () {
    return {
      copyright: '@版权所有（C）2017-' + new Date().getFullYear()
    }
  },
  methods: {
    handleTest () {
      this.$message.warning('文档完善中...')
    },
    handleGo () {
      this.$router.push({
        name: 'recruitAgent'
      })
    },
    handleGoRecruitment (type) {
      this.$router.push({
        name: type === 1 ? 'recruitAgent' : 'recruitingFirm',
        query: {
          type
        }
      })
    },
    handleGoInformation (type) {
      this.$router.push({
        name: 'information',
        query: {
          type
        }
      })
    },
    handleGoBusiness (type) {
      this.$router.push({
        name: 'business',
        query: {
          type
        }
      })
    },
    handleGoSolution (type) {
      this.$router.push({
        name: 'solution',
        query: {
          type
        }
      })
    },
    handleGoAbout (type) {
      this.$router.push({
        name: 'about',
        query: {
          type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  box-sizing: border-box;
  width: 100%;
  font-size: 12px;
  color: #fff;
  background: #333333;
  height: 453px;
  .footer-in {
    width: 80%;
    height: 380px;
    margin: 0 auto;
    padding: 60px 0;
    box-sizing: border-box;
    li {
      cursor:pointer;
      h2 {
        font-size: 24px;
        font-weight: 700;
        color: white;
        margin-bottom: 10px;
      }
    }
    .left {
      width: 100%;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-evenly;
      cursor:pointer;
      li {
        font-size: 18px;
        color: #b3b3b3;
        line-height: 38px;
        text-align: center;
      }
    }
    .img-list {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 440px;
      li {
        text-align: center;
        margin-right: 65px;
        span {
          display: block;
          margin: 0 auto;
          text-align: center;
          opacity: 0.8;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ffffff;
        }
        img {
          width: 138px;
          height: 138px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: calc(453px - 380px);
    border-top: 1px solid #666;
    .footer-inner {
      a{
        color: #b3b3b3;
        font-size: 18px;
      }
      font-size: 18px;
      color: #b3b3b3;
    }
  }
}
.active:hover{
  color: #00a0ac;
}
</style>

<style lang='scss' scoped>
// 手机端 样式
@import './mediaStyle/footer';
</style>
